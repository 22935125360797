import { writable } from 'svelte/store';

import type { UserResponseDto } from '$lib/api/apiV1';

const userStore = writable<{
  user?: UserResponseDto | null;
}>();

export default {
  subscribe: userStore.subscribe,
  set: userStore.set
};
